import { RegisterStoreKeys, useRegisterStore } from '@/stores/register.store'
import { RouteLocationNormalized } from 'vue-router'

const isRoutingByApp = (hasPageName: boolean) => {
  return hasPageName
}

const isHasRequiredKeys = (requiredKeys?: RegisterStoreKeys[][]) => {
  const registerStore = useRegisterStore()

  if (!requiredKeys || requiredKeys.length === 0) {
    return false
  }

  return requiredKeys.some((keyGroup) =>
    keyGroup.every((key) => registerStore.hasValue(key)),
  )
}

const isDontHaveKeys = (notRequiredKeys?: RegisterStoreKeys[][]) => {
  const registerStore = useRegisterStore()

  if (!notRequiredKeys || notRequiredKeys.length === 0) {
    return true
  }

  return notRequiredKeys.some((keyGroup) =>
    keyGroup.every((key) => !registerStore.hasValue(key)),
  )
}

export const GuardRouter =
  (
    requiredKeys?: RegisterStoreKeys[][],
    notRequiredKeys?: RegisterStoreKeys[][],
  ) =>
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
    if (
      isRoutingByApp(!!from.name) ||
      (isHasRequiredKeys(requiredKeys) && isDontHaveKeys(notRequiredKeys))
    ) {
      next()
    } else {
      if (/^\/tablet-/.test(to.path)) {
        next({ path: '/tablet', query: to.query })
      } else {
        next({ path: '/', query: to.query })
      }
    }
  }
